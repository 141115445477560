import Cmp from '../Cmp';
//import EmployeeEditor from './Editor';
import PubSub from 'pubsub-js';


class EmployeeList extends Cmp{
  constructor(props){
    super(props)
    this.state={
      loading:true,
      test:false,
      edit_em:{em_id:0,em_name:'-'},

      data:[],
      total:0,
      page:1,
      page_size:50,

      searchParam:{em_status:"-",field:"em_name",value:"",em_org_info:[]},
      sort:{}
    }
  }

  loadData(callback){
    const {page,page_size} = this.state
    const H = this._H_();
    const me = this;
    me.setState({loading:true})

    const param = {...this.state.searchParam};
    if(!param.em_status || param.em_status=='-')delete param.em_status;
    if(param.value){
      param[param.field] = param.value
    }
    if(param.em_org_info.length>0){
      param.em_org_info = param.em_org_info.join('/')
    }else{
      delete param.em_org_info
    }
    delete param.field;
    delete param.value;

    /*em_id:me.props.emId,*/
    H.http.get(H.serviceUrl('employee/get_list'),{...param,...{page:page,page_size:page_size},...this.sort},function(res){
      console.log(res);
      const r = res.result||{};
      me.setState({
        loading:false,
        data:r.items||[],
        total:r.total,
        page_size:(r.page_size||50)
      });
    });
  }

  toPage=(page,ps)=>{
    this.setState({
      page:page,
      page_size:ps
    },()=>{
      this.loadData();
    })
  }


  componentDidMount() {
    this.loadData();
  }

  removeItem=(em_id)=>{
    alert("无效功能/"+em_id)
  }

  endEdit(){
    PubSub.publish('em_editor',"exec_save");
  }

  closeEditor(){
    this.setState({edit_em:{em_id:0,em_name:''}});    
    console.log(this.state.edit_em);
  }

  editItem=(em_id,rcd)=>{
    this.setState({test:true,edit_em:rcd});
    /*
    this.setState({popup:{
      title:"正在编辑 ["+rcd.em_name+'] 的信息',
      visible:true,
      content:<EmployeeEditor emId={em_id}/>,
      cfg:{footer:<Space size={10}><Button onClick={()=>{this.closePopup()}}>取消</Button><Button type="primary">保存</Button></Space>}
    }})
    */
  }
  createItem(em_id,rcd){
    this.setState({edit_em:{em_id:-1,em_name:''}});
    console.log(this.state.edit_em);
  }
    
}

export default EmployeeList;