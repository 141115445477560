import React, {  useEffect, useState } from 'react';
import { Button, Divider, Skeleton, Space, Tree,Drawer,message,Modal } from 'antd';
import { DownOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import Helper from '../../func.js';
import XnodeEditor from './Editor.jsx';
import BrowserAccess from "./BrowserAccess";
const { confirm } = Modal;
const {http} =Helper;
const service=(p)=>{
    return Helper.serviceUrl('node/'+p)
}

/**
 *
 * @param {{int}} props.projectId
 * @returns {JSX.Element|string}
 * @constructor
 */
const XnodeList= (props)=>{

    const [treeData,setTreeData] = useState([]);
    const [loading,setLoading] = useState(true);
    const [editing,setEditing] = useState(0);
    const [create,setCreateStatus] = useState(false);
    const [selectedNode,setSelectedNode] = useState('');
    const [isShowAccesBrowser,setAccesBrowser] = useState(false);
    const [cfg,setCfg]=useState({/*expandedKeys:null,*/defaultExpandAll:true})
    

    
    const getSelNodeId=()=>{return  selectedNode;}
    const hasSel=()=>{return !!selectedNode;}

    const onSelect = (selectedKeys, info) => {
      //console.log(selectedKeys)
      //console.log(info)
      setSelectedNode(selectedKeys[0]);
    };
    const onExpand=(expandedKeys)=>{
      setCfg({...cfg,...{expandedKeys:expandedKeys}})
    }
    const onRemove= ()=>{
      if(hasSel()){
        confirm({
          title: '确认移除吗',
          icon: <ExclamationCircleOutlined />,
          content: '移除节点后，不可恢复，请谨慎操作',
          okText: '移除',
          cancelText: '取消',
          okType: 'danger',
          onOk() {
            http.get(service('remove'),{node_id:getSelNodeId()||0},function(res){
              //setTreeData(res.result)
              message.success('已移除')
              setLoading(false)
              refresh();
            });
          }
        });
        
      }else{
        message.error('选择有误');
      }
    }

    const onEdit=()=>{
      if(hasSel()){ setEditing(selectedNode)}
      else message.error('选择有误');
    }

    const onBrowseAcces=()=>{
        if(hasSel()){ setAccesBrowser(true)}
        else message.error('选择有误');
    }

    const onCloseEditor=()=>{
      setEditing(0)
    }
    const refresh=()=>{
        setLoading(true)
        if(!props.projectId) { return ;}
        http.get(service('tree'), {project_id: props.projectId}, function (res) {
            setTreeData(res.result)
            setLoading(false)

            setCreateStatus(false)
            setEditing(0)
        }, function (data, error) {
            console.error(error);
        });
    }

    useEffect(()=>{refresh();},[props.projectId])// eslint-disable-line
    useEffect(()=>{console.log(selectedNode)},[selectedNode]);
   
    if(!props.projectId)return ''
    return (
        <>
            <Skeleton type='list' statistic={false} loading={loading} active paragraph={{ rows: 15 }}>
                <Space>
                    <Button onClick={onBrowseAcces}>权限</Button>
                    <Button onClick={()=>setCreateStatus(true)}>添加</Button>
                    <Button onClick={onEdit}>修改</Button>
                    <Button danger={true} onClick={onRemove}>移除</Button>
                </Space>
                <Divider />
                <Tree
                showLine
                switcherIcon={<DownOutlined />}
                selectedKeys={[selectedNode]}
                //defaultSelectedKeys={selected}
                onSelect={onSelect}
                treeData={treeData}
                onExpand={onExpand}
                {...cfg}
                />
            </Skeleton>
            <Drawer title="修改节点" placement="right" onClose={onCloseEditor} visible={!!editing}>
                <XnodeEditor xnodeId={editing} afterSave={refresh} msgTopic={props.msgTopic||''} />
            </Drawer>
            <Drawer title="创建节点" placement="right" onClose={()=>setCreateStatus(false)} visible={create}>
                <XnodeEditor xnodeId="+" afterSave={refresh} defaultData={{node_parent_id:getSelNodeId(),project_id:props.projectId}} msgTopic={props.msgTopic||''} />
            </Drawer>
            <Drawer title="拥有该权限的对象" placement="right" onClose={()=>setAccesBrowser(false)} visible={isShowAccesBrowser}>
                <BrowserAccess xnodeId={selectedNode}></BrowserAccess>
            </Drawer>
        </>
    )
}
export default XnodeList;