import { useNavigate } from "react-router-dom";
import {
    SisternodeOutlined,
    UserOutlined,
    KeyOutlined,
    HomeOutlined,
    ApartmentOutlined,
    AppstoreOutlined,
    ApiOutlined
  } from '@ant-design/icons';
    
import { Menu } from 'antd';
import {useContext, useEffect, useState} from "react";
import {Context} from "../Context";
import Helper from "../../func";

/**
 *
 * @param {function} props.afterSelect
 * @return {JSX.Element}
 * @constructor
 */
const allItems = [
    {
        key: 'home',
        icon: <HomeOutlined />,
        label: '首页',
    },
    {
        key: 'employee',
        icon: <ApartmentOutlined />,
        label: '员工',
    },
    {
        key: 'user',
        icon: <UserOutlined />,
        label: '用户/账号',
    },
    {
        key: 'role',
        icon: <KeyOutlined />,
        label: '权限组',
    },
    {
        key: 'node',
        icon: <SisternodeOutlined />,
        label: '节点',
    },
    {
        key: 'project',
        icon: <AppstoreOutlined />,
        label: '内部应用',
    },
    {
        key: 'api',
        icon: <ApiOutlined />,
        label: '外部API应用',
    },
]

 const MainMenu=(props)=> {
    const setting = useContext(Context)
    const navigate = useNavigate();
    const [items,setItems] = useState([]);
    const _onClickMenuItem=(item)=>{
        let keyName = item.key.replace(/\./g,'/')
        navigate(Helper.pathname(keyName))
        if(typeof props.afterSelect==='function'){
            props.afterSelect(keyName)
        }
    }

    useEffect(function (){
        const r=[];
        allItems.forEach(function (item,index){
            if(item.key==='home' || setting.access.indexOf(item.key)) {
                r.push(item)
            }
        })
        setItems(r)
    },[setting])


    return <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={['1']}
                onClick={_onClickMenuItem}
                items={items}
            />
}

export default MainMenu